<template>
  <header class="aplayzmo_header">
    <nav>
      <ul class="aplayzmo_header_box">
        <li>
          <a>
            <img src="/media/images/icon_back_24.svg" @click="$emit('click-pre')" />
          </a>
        </li>
        <li>
          <h2 class="aplayzmo_header_title">
            <slot name="header_title">스페이스 등록</slot>
          </h2>
        </li>
        <li v-if="isTime" class="btn_close " @click="$emit('link-myspace')">
          <img src="/media/images/icon_close_24.svg" />
        </li>
        <li v-else class="btn_close " @click="$emit('link-UpdateCuration')">
          <img class="time_img" src="/media/img/acute.png" />
        </li>
      </ul>
    </nav>
    <slot name="progressbar"> </slot>
  </header>
</template>
<script>
export default {
  name: 'MoSpaceHeader',
  props: {
    isTime: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['click-pre', 'link-myspace', 'link-UpdateCuration'],
  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>
<style scoped src="@/assets/css/common/moheader.css"></style>
